.main-heading {
    margin: 1em 0;
    
    @media screen and (min-width: 40em) {
        margin: 2em 0;
    }

    &__left {
        border-bottom: 3px solid var(--secondary);
        display: inline-block;
        padding-bottom: 10px;
    }

    &__right {
        border-bottom: 1px solid var(--gray-300);
        display: inline-block;
        padding-bottom: 11px;
    }
}