.product-detail {
    margin: -1em;
    margin-bottom: 3em;

    @media screen and (min-width: 60em) {
        margin: 0 0 3em 0;
    }

    &__img {
        height: 35em;

        @media screen and (min-width: 60em) {
            border-radius: 0.5em;
            height: 55em;
            border: 1px solid var(--gray-400);
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        }
    }

    &__overview {
        @media screen and (min-width: 40em) {
            display: flex;
            flex-direction: row-reverse;
            margin-left: 0.5em;
            margin-right: 1.5em;
        }

        @media screen and (min-width: 60em) {
            margin-left: 0;
        }
    }

    &__content {
        min-height: 10em;
        background-color: white;
        margin-top: -5em;
        border-radius: 0.5em;
        padding: 0 1em 1em;

        position: relative;
        width: 96%;
        left: 2%;

        @media screen and (min-width: 40em) {
            flex-grow: 1;
            flex-basis: calc(40% - 1em);
            left: 1em;
        }

        @media screen and (min-width: 48em) {
            max-width: 23em;
        }
    }

    &__desc {
        margin: 1em 0.5em;
        font-size: 1.1rem;

        @media screen and (min-width: 40em) {
            margin: 0;
            flex-grow: 1;
            flex-basis: calc(60% - 1em);
            display: flex;
            flex-direction: column;
        }

        &__content {
            padding: 1em 1em 3em;
            line-height: 1.5;
            letter-spacing: 0.01em;

            @media screen and (min-width: 40em) {
                flex-grow: 1;
            }
        }
    }

    &__carousel {
        transform: translateY(-2em);

        &__links {
            max-width: 7em;
            margin: 0 auto;
            text-align: center;
        }

        &__link {
            width: 0.5em;
            height: 0.5em;
            background-color: var(--gray-200);
            margin: 0.2em;
            border-radius: 100%;
            display: inline-block;
        }
    }

    &__price {
        text-align: center;
        color: var(--secondary);
        font-weight: 400;
        font-size: 3rem;
        margin-top: -0.5em;

        &__cents {
            font-size: 2rem;
        }
    }

    &__stock {
        margin: 0 1em 0.3em;
        display: inline-block;
    }
}

.product-desc {
    margin-bottom: 3em;

    &__heading {
        padding-left: 0.5em;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 0.05em;
    }
}

.product-spec {
    list-style-type: disc;
    flex-basis: 100%;

    &__list {
        padding: 1em 1.2em;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
         
        & > *+* {
            margin-top: 0.5em;
        }
    }
}