.search-bar {
    &__input {
        width: 100%;
        border: none;
        background-color: var(--gray-200);
        border-radius: 0.5em;
        padding: 0.5em 1em;
        line-height: 2.5em;
    }

    &--main {
        @media screen and (min-width: 60em) {
            flex-grow: 1;
            margin: 0 0.8em;
        }

        &>input {
            display: block;
            width: 100%;
            font-size: 0.95rem;
            background-color: var(--gray-300);
            border: none;
            border-radius: 0.5em;
            padding: 0.9em;
            font-weight: 300;
            letter-spacing: 0.02em;
        }
    }
}

.mobile-search-container {
    padding: 0 1em;
    @media screen and (min-width: 60em) {
        padding: 0;
    }
}

.topbar-search {
    display: none;

    @media screen and (min-width: 30em) {
        display: block;
    }
}

.mobile-search {
    padding: 1em 0;

    @media screen and (min-width: 30em) {
        display: none;
    }
}