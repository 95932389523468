.topbar {
    padding: 0.8em;
    display: flex;
    // justify-content: space-between;

    @media screen and (min-width: 60em) {
        padding: 1em 0.8em;
    }

    & *+* {
        margin: 0;
    }

    &>* {
        flex-basis: 50%;
    }

    &__right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

