.ais-SearchBox {
    display: block;
}

.ais-SearchBox-form {
    display: block;
    position: relative;
}

.ais-SearchBox-input {
    border: none; 
    outline: none;
    display: block;
    width: 100%;
    font-size: 1.3rem;
    padding: 0.8em;
    border-radius: 0.5em;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    background-color: white;
}

.ais-SearchBox-submit {
    padding: 1em;
    border: none;
    outline: none;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.5em;
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: 500;
    display: block;
    width: 100%;
    margin-top: 1em;


    background-color: var(--secondary);
    color: var(--light-text);

    &:hover {
        background-color: var(--primary);
    }

    &::after {
        content: 'Search products';
    }
}

.ais-SearchBox-submitIcon{
    display: none;
}

.ais-SearchBox-reset {
    position: absolute;
    right: 0.8em;
    top: 0.6em;
    margin: 0;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 1em;
    cursor: pointer;
    color: var(--secondary);

    @media screen and (min-width: 30em) {
        top: 0.9em;
    }

    @media screen and (min-width: 40em) {
        top: 1.1em;
    }
}

.ais-InfiniteHits-loadMore {
    padding: 1em;
    border: none;
    outline: none;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.4em;
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: 500;
    display: block;
    width: 100%;
    margin-top: 1em;


    background-color: var(--secondary);
    color: var(--light-text);

    &:hover {
        background-color: var(--primary);
    }
}

.ais-InfiniteHits-list {
    margin-left: -0.3em;
    margin-right: -0.3em;
    @media screen and (min-width: 20em) {
        display: flex;
        flex-wrap: wrap;


        & > * + * {
            margin-top: 0;
        }

        & > * {
            flex-basis: 50%;
            flex-shrink: 0;
            flex-grow: 0;
            padding: 0.3em;
        }
    }

    @media screen and (min-width: 40em) {
        & > * {
            flex-basis: 25%;
            max-width: 30em;
        }
    }
}