.sidebar {
    background-color: white;
    width: 85%;
    position: fixed;
    height: 100vh;
    z-index: 10;
    max-width: 22em;
    display: none;
    
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
        display: none;
    }

    @media screen and (min-width: 40em) {
        max-width: 18em;
    }

    @media screen and (min-width: 60em) {
        display: block;
        width:18em;
        top: 0;
        left: 0;
    }

    &.open {
        display: block;
    }
}