.user-acc-summary {
    display: flex;
    padding: 0.3em 1em;

    &__avatar {
        height: 4em;
        width: 4em;
        border-radius: 50%;
        padding: 0.2em;
        border: 1px solid var(--gray-700);
        flex-shrink: 0;
    }

    &__name {
        padding: 0.5em 1em;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;

        &>p {
            font-weight: 400;
            white-space: nowrap;
        }

        &>a {
            margin-top: 0.2em;
            font-size: 0.9rem;
        }
    }
}