:root {
    --primary: #dd6b20;
    --primary-dark: #c05621;
    --primary-light: #ed8936;

    --secondary: #319795;
    --secondary-dark: #2c7a7b;
    --secondary-light: #38b2ac;

    --gray-100: #f7fafc;
    --gray-200: #edf2f7;
    --gray-300: #e2e8f0;
    --gray-400: #cbd5e0;
    --gray-500: #a0aec0;
    --gray-600: #718096;
    --gray-700: #4a5568;
    --gray-800: #2d3748;
    --gray-900: #1a202c;

    --light-text: var(--gray-100);
    --dark-text: var(--gray-800);

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 0.01rem;
    font-weight: 300;
    font-size: calc(2vw + 0.4em);
    line-height: 1.4;
    box-sizing: border-box;
    scroll-behavior: smooth;
    color: var(--dark-text);

    @media screen and (min-width: 20em) {
        font-size: calc(1vw + 0.45em);
    }

    @media screen and (min-width: 30em) {
        font-size: calc(0.5vw + 0.6em);
    }

    @media screen and (min-width: 40em) {
        font-size: calc(1vw + 0.4em);
    }

    @media screen and (min-width: 60em) {
        font-size: 13px;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-weight: inherit;
}

#root {
    margin: 0;
}

body *+* {
    margin-top: 0.8em;
}

a {
    text-decoration: none;
    color: var(--secondary);
}

a:hover {
    color: var(--primary);
}

h1 {
    font-weight: 400;
}

h2 {
    font-weight: 500;
}

h3 {
    font-weight: 600;
}

h4 {
    font-weight: 700;
}

h5 {
    font-weight: 800;
}

h6 {
    font-weight: 900;
}

img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
}

button {
    outline: 0;
    font-family: inherit;
}

body {
    background-color: var(--gray-200);
}

input {
    font-family: inherit;
}

li {
    list-style-type: none;
}

.shadowed {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.hr {
    border-bottom: 1px solid var(--gray-300);
}

.panel {
    background-color: white;
    border-radius: 0.5em;
}


@import "container";
@import "content";
@import "banner";
@import "main-heading";
@import "insight";
@import "badge";
@import "btn";
@import "sidebar";
@import "topbar";
@import "top-nav";
@import "product-summary";
@import "section-title";
@import "nav";
@import "logo";
@import "product-details";
@import "product-option";
@import "search";
@import "cart";
@import "user-acc-summary";
@import "checkout";
@import "notifications";
@import "form";
@import "preloader";
@import "algolia";
@import "dashboard";