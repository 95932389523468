.logo {
    padding: 0.2em 1em;

    &>h1 {
        font-weight: 900;
        padding-top: 0.4em;
    }

    &-left {
        color: var(--secondary);
    }

    &-right {
        color: var(--gray-700);
        margin-top: 0;
    }

    &--mobile {
        margin-left: 0.5em; font-weight: 500;

        @media screen and (min-width: 60em) {
            display: none;
        }
    }
}