.dashboard {
    @media screen and (min-width: 60em) {
        display: flex;
    }

    &__left {
        @media screen and (min-width: 60em) {
            flex-basis: calc(70% - 3em);
            padding-right: 1.5em;
        }
    }

    &__right {
        @media screen and (min-width: 60em) {
            padding-left: 1.5em;
            border-left: 1px solid var(--gray-300);
            margin-top: 0;
            flex-basis: calc(30%);
            min-height: 100vh;
        }
    }

    &__overview {
        flex-basis: 45%;

        @media screen and (min-width: 40em) {
            flex-basis: 20%;
        }

        & > p {
            font-size: 0.8rem;
        }

        & > h2 {
            font-size: 2rem;
            margin-top: 0;
            
            @media screen and (min-width: 60em) {
                margin-top: 0.3em;
            }
        }
    }
}