.nav {
    padding: 0em 1em;

    & *+*{
        margin-top: 0;
    }

    &__group__title {
        margin: 1.5em 0 0.8em;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 300;
        color: var(--secondary-dark);
        letter-spacing: 0.08em;
    }

    &__item {
        border-bottom: 1px solid var(--gray-200);

        & > a {
            display: flex;
            align-items: center;
            padding: 1.2em 0;
            color: var(--dark-text);

            &.active {
                color: var(--primary);
            }

            &:hover{
                color: var(--primary);
            }

            & > img {
                width: 1.5em;
                height: 1.5em;
            }

            & > span {
                padding-left: 1em;
                font-weight: 500;
            }


        }
    }
}

#nav-toggle {
    cursor: pointer;

    @media screen and (min-width: 60em) {
        display: none;
    }
}