.btn {
    padding: 1em;
    border: none;
    outline: none;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.2em;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 500;
    display: inline-block;
    margin: 0.2em;


    &--secondary {
        background-color: var(--secondary);
        color: var(--light-text);

        &:hover {
            background-color: var(--primary);
        }
    }

    &--primary {
        background-color: var(--primary);
        color: var(--light-text);

        &:hover {
            background-color: var(--secondary);
        }
    }
}