.checkout {
    margin-left: -0.5em; margin-right: -0.5em;

    & > *:nth-child(2) {
        display: none;
    }

    @media screen and (min-width: 40em) {
        display: flex;

        & > * {
            flex-basis: 50%;
            flex-grow: 1;
        }

        & > *:nth-child(1) {
            max-width: 30em;
            margin-right: 0.5em;
        }

        & > *:nth-child(2) {
            display: block;
            margin-top: 0;
            margin-left: 0.5em;
            padding-left: 1em;
        }
    }

    @media screen and (min-width: 60em) {
        margin: 0;
        margin-top: 1em;
    }
}