.notifications {
    position: fixed;
    z-index: 30;
    top: 2em;
    right: 0;
    text-align: right;
    max-width: 90%;
}

.notification {
    padding: 0.2em 1em;
    font-size: 1.3rem;
    font-weight: 400;
    background-color: #4545f4d1;
    color: #000035;
    border-top-left-radius: 0.2em;
    border-bottom-left-radius: 0.2em;

    &--success {
        background-color: #1dca1ded;
        color: #024802;
    }

    &--danger {
        background-color: red;
    }

    &--warning {
        background-color: orange;
    }
}