.insights-container {

    @media screen and (min-width: 30em) {
        display: flex;

        & > *+* {
            margin-top: 0;
        }

        & > *:nth-child(3) {
            margin-left: 0.5em;
        }
    }

    @media screen and (min-width: 40em) {
        margin: 4em 0;
        & > *+* {
            margin-left: 1em;
        }
    }

    & > *:nth-child(1) {
        display: none;

        @media screen and (min-width: 40em) {
            margin-left: 1em;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--secondary);
        }

        @media screen and (min-width: 70em) {
            width: 15em;
        }

        & h1 {
            transform: rotate(-90deg);
            transform-origin: center;
            color: var(--light-text);
            font-weight: 500;
        }
    }
}

.insight {
    padding: 1em;
    border-radius: 0.5em;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    background-color: white;
    
    @media screen and (min-width: 40em) {
        max-width: 30em;

        background-color: initial;
        box-shadow: initial;
        border-radius: initial;
    }

    @media screen and (min-width: 40em) {

        &:nth-child(n + 2){
            margin-top: 3em;
        }
        
    }

    & h4 {
        margin-top: 0;
    }

    & p {
        @media screen and (min-width: 40em) {
            max-width: 80%;
        }  
    }
}

