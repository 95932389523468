.cart {
    top: 0;
    right: 0;
    margin: 0;
    width: 95%;
    z-index: 20;
    padding: 1em;
    display: none;
    height: 100vh;
    max-width: 25em;
    position: fixed;
    border: 1px solid var(--gray-300);
    background-color: var(--gray-200);

    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
        display: none;
    }

    &::after {
        content: ' ';
        display: block;
        margin-top: 2em;
    }

    &.open {
        display: block;
    }
}