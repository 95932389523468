.product-options {
    &__heading {
        font-size: 0.9rem;
        padding-left: 0.1em;
        margin-bottom: -0.5em;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -0.2em;
    }
}

.product-option {
    min-width: 3em;
    padding: 0.1em 0.4em;
    text-align: center;
    border-radius: 0.3em;
    margin: 0.2em;
    background-color: var(--gray-100);
    border: 2px solid var(--gray-400);
    cursor: pointer;

    &:hover {
        background-color: var(--gray-400);
    }

    &.selected {
        background-color: var(--gray-400);
    }
}