.product-row {
    margin-left: -0.3em;
    margin-right: -0.3em;
    @media screen and (min-width: 20em) {
        display: flex;
        flex-wrap: wrap;


        & * + * {
            margin-top: 0;
        }

        & > * {
            flex-basis: 50%;
            flex-shrink: 0;
            flex-grow: 0;
            padding: 0.3em;
        }
    }

    @media screen and (min-width: 40em) {
        & > * {
            flex-basis: 25%;
            max-width: 30em;
        }
    }
}

.product-summary {

    &__img {
        display: block;
        position: relative;
        border-radius: 0.5em;
        border: 1px solid var(--gray-300);

        &>img {
            display: block;
            border-radius: 0.5em;
            height: 18em;

            @media screen and (min-width: 20em) {
                height: 15em;
            }

            @media screen and (min-width: 30em) {
                height: 18em;
            }

            @media screen and (min-width: 40em) {
                height: 13em;
            }

            @media screen and (min-width: 72em) {
                height: 18em;
            }
        }

        &::after {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            position: absolute;
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        }
    }

    &__details {
        left: 3%;
        width: 94%;
        padding: 0.5em;
        margin-top: -3em;
        position: relative;
        border-radius: 0.5em;
        background-color: white;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        background-image: linear-gradient(to top, white 70%, var(--gray-200));


        min-height: 10em;

        @media screen and (min-width: 60em) {
            min-height: 11em;
        }

        @media screen and (min-width: 77em) {
            min-height: auto;
        }
    }

    &__title {
        
        font-weight: 500;
        letter-spacing: 0.02em;
        font-size: 1.2rem;
        display: block;
        margin-top: 1em;

        & > a {
            color: var(--dark-text);
            display: block;
        }

        @media screen and (min-width: 20em) {
            min-height: 3em;
        }

        @media screen and (min-width: 25em) {
            font-size: 1rem;
            margin-top: 1em;
        }

        @media screen and (min-width: 27em) {
            min-height: 1em;
        }

        @media screen and (min-width: 40em) {
            min-height: 3em;
        }

        @media screen and (min-width: 70em) {
            min-height: 1.5em;
        }

        &:hover {
            color: var(--primary);
        }
    }

    &__price {
        color: var(--gray-800);
        font-weight: 600;
        margin-top: 0.5em;
        font-size: 1.3rem;
        letter-spacing: 0.03em;
    }

    &__rating {
        display: flex;
        align-items: center;
        margin-top: 0.8em;

        & *+* {
            margin-top: 0;
        }

        >svg {
            width: 1em;
            height: 1em;
            fill: var(--secondary-light);
        }

        >svg:nth-child(5) {
            fill: var(--gray-400);
        }

        &__reviews {
            font-size: 0.8rem;
            display: block;
            padding-left: 1em;
            margin-top: -0.1em;
        }
    }
}

.small-product {
    width: 2.3em;
    height: 2.8em;
    border-radius: 0.5em;
    display: inline-flex;
    margin: 0;
    overflow: hidden;
    border: 2px solid white;
    transition-timing-function: ease-out;
    transition-duration: 100ms;
    transform: perspective(100px) rotateY(30deg);
    background-color: var(--secondary);

    &:nth-child(n + 1){
        margin-left: -8px;

        @media screen and (min-width: 60em) {
            margin-left: -15px;
        }

        &:hover {
            transform: translateX(-8px);
            // z-index: 10;
        }
    }

    
}