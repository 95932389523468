.section-title{

    &__heading {
        font-weight: 400;
    }

    &__desc {
        font-weight: 200;
        margin-top: 0.2em;
    }
}

section + section {
    margin-top: 3em;
}