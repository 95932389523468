.badge {
    color: #2c7a7b;
    background-color:  #b2f5ea;
    border-radius: 0.5em;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 0.7rem;
    padding: 0.4em 0.8em;

    @media screen and (min-width: 50em) {
        font-size: 0.5rem;
    }

    @media screen and (min-width: 60em) {
        font-size: 0.6rem;
    }
}