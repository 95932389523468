.banner {
    margin: -1em;
    padding: 15em 0 6em;  
    color: var(--light-text);
    background-size: cover;
    background-position: center;
    background-color: var(--secondary);
    background-image: linear-gradient(to top, var(--gray-200) 1%,hsla(220, 25.7%, 13.7%, 0.3) 10%, transparent 40%),url('https://scontent.fhre3-1.fna.fbcdn.net/v/t1.0-9/89947418_1632434520227805_8450215835527544832_n.jpg?_nc_cat=105&_nc_sid=8024bb&_nc_oc=AQkRjWwA78AqwS3iUBR4n77ZchI2PJtJ-N1JlSwt9NtYy2O3DScC-K58V9Rm--hsfFA&_nc_ht=scontent.fhre3-1.fna&oh=8c35521214543530bade2cba14c95450&oe=5E988BBD');

    @media screen and (min-width: 40em) {
        padding-top: 20em;
    }

    @media screen and (min-width: 60em) {
        margin: 0;
        border-radius: 0.5em;
    }

    @media screen and (min-width: 65em) {
        padding-top: 30em;
    }

    @media screen and (min-width: 85em) {
        padding-top: 40em;
    }

    & > h2 {
        padding-left: 0.5em;
        text-shadow: 0px 0px 0px var(--gray-700);

        @media screen and (min-width: 20em) {
            font-size: 2rem;
        }

        @media screen and (min-width: 40em) {
            font-size: 2.5rem;
        }
    }
}

.homepage-search {
    margin-top: -5em;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 40em){
        display: flex;
        align-items: stretch;
    }

    @media screen and (min-width: 60em){
        width: 98%;
    }

    &__bar {
        padding: 1em;
        border-radius: 2em;
        background-image: linear-gradient(to top, var(--gray-100), var(--gray-300));

        @media screen and (min-width: 40em){
            flex-basis: 0%;
            flex-grow: 1;
        }

        @media screen and (min-width: 50em){
            flex-grow: 1.3;
        }

        @media screen and (min-width: 60em){
            flex-grow: 1;
        }

        @media screen and (min-width: 70em){
            flex-grow: 1.2;
        }

        @media screen and (min-width: 75em){
            flex-grow: 1.3;
        }

        @media screen and (min-width: 80em){
            flex-grow: 1.5;
        }

        & > input {
            width: 100%;
            border-radius: 10em;
            border: none;
            outline: none;
            padding: 0.8em 1.5em;
            font-size: 1.1rem;
        }

        & > button {
            width: 100%;
            margin-top: 1em;
            border-radius: 10em;
            padding: 1em;
        }
    }
}

.overview {
    display: flex;
    margin-top: 1em;

    @media screen and (min-width: 40em){
        flex-basis: 0%;
        flex-grow: 1;
        margin-top: 0;
        margin-left: 0.5em;
    }

    &--search {
        display: none;

        @media screen and (min-width: 40em){
            display: flex;
        }
    }
    
    & > *+* {
        margin-left: 0.5em;
    }

    &__item {
        flex-grow: 1;
        flex-basis: 33.333%;
        margin-top: 0;
        display: flex;
        text-align: center;
        border-radius: 0.5em;
        height: 8em;
        position: relative;

        @media screen and (min-width: 40em) {
            border-radius: 0.5em;
            height: initial;
        }

        & > img {
            border-radius: 0.5em;
            background-image: linear-gradient(to top, var(--gray-500), transparent);

            @media screen and (min-width: 40em) {
                border-radius: 0.5em;
            }
        }

         & > p {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin-top: 0;
            font-weight: 500;
            padding-left: 1em;
            position: absolute;
            padding-bottom: 0.5em;
            color: var(--light-text);
            background-image: linear-gradient(to top, var(--gray-900) 1%, transparent);

            display: flex;
            align-items: flex-end;

            border-radius: 0.5em;

            @media screen and (min-width: 40em) {
                border-radius: 0.5em;
                padding-left: 1.2em;
            }
         }
    }
}