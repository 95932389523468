.top-nav {
    display: flex;
    font-size: 1.2rem;
    white-space: nowrap;

    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
        display: none;
    }

    @media screen and (min-width: 52em) {
        font-size: 1.4rem;
    }

    &__item {
        margin: 0 0 1em 0.5em;
        padding: 0.5em 1em;
    }
}